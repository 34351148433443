import React, { Component } from 'react'

import styled from 'styled-components'


const Item = styled.li`
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 10px 0px;
  border-bottom: 1px dashed rgba(255,255,255,.25);
  @media screen and (max-width: 39.9375em) {
    flex-wrap: wrap;
  }
`
const ItemDetail = styled.div`
  margin-right: 10px;
  color: #ffffff;
`


const List = (props) => {
  const { itemCash, itemCharge } = props.details
  const moreThan = itemCash >= 20000

  return (
    <Item>
      <ItemDetail><span>現金股利: </span><span>{itemCash}</span> 元</ItemDetail>
      {moreThan ? <div><span>應繳補充保費: </span><span>{itemCharge}</span></div> : '不用繳補充保費'}
    </Item>
  )
}

export default List
