import React, { Component } from 'react'
import styled from 'styled-components'
import style from './style.module.scss'

const Form = styled.form`
  margin-bottom: 40px;
  padding: 20px 0px;
  border-bottom: 3px solid rgba(108,218,244,1);
  box-shadow: 0 2px 40px 1px rgba(0,0,0,.25);
  background: rgba(255,255,255,.35);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 39.9375em) {
    order: 1;
    margin-bottom: 0;
    padding: 20px 10px;
  }
  p {
    border-bottom: 1px solid rgba(255,255,255,.25);
    padding: 0 25px 20px;
    width: 100%;
    margin: 0 0 20px 0;
    @media screen and (max-width: 39.9375em) {
      font-size: 14px;
      padding: 0 0px 10px;
    }
  }
`

const InsertLabel = styled.label`
  padding: 0 25px;
  @media screen and (max-width: 39.9375em) {
    padding: 0px;
  }
`
const Insert = styled.input`
  width: 180px;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid rgba(255,255,255,.25);
  box-shadow: none;
  transition: all .2s ease-in;
  &:focus {
    box-shadow: 0 0 10px 2px rgba(0,0,0,.25)
  }
  @media screen and (max-width: 39.9375em) {
    border-radius: 4px;
    padding: 10px;
    width: 100%;
  }
`
const InsertBtn = styled.button`
  margin-left: 10px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 1;
  border-radius: 50%;
  background: #ffd400;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all .2s ease-in;
  box-shadow: none;
  &:hover {
    box-shadow: 0 0 10px 2px rgba(0,0,0,.25)
  }
  @media screen and (max-width: 39.9375em) {
    width: calc(100% - 20px);
    width: 100%;
    border-radius: 4px;
    margin: 10px auto 0;
  }
`
const Alert = styled.div`
  margin-top: 10px;
  color: #ffd400;
  padding: 0 25px;
`


class AddItem extends Component {
  state = {
    cashValue: 0,
    charge: 0
  }
  cashValue = React.createRef()

  onChangeCash = e => {
    this.setState({
      cashValue: e.target.value,
      charge: parseInt(Math.round(e.target.value * 0.0191), 10)
    })
  }

  createItem = (e) => {
    e.preventDefault()

    const item = {
      itemCash: this.cashValue.current.value,
      itemCharge: this.state.charge
    }

    this.props.addItem(item)
    e.currentTarget.reset()
  }

  render() {
    const { cashValue, charge } = this.state
    const isPayment = cashValue >= 20000
    return (
      <Form onSubmit={this.createItem}>
        <p>若現金股利小於2萬元，免繳補充保費</p>

          <InsertLabel>現金股利  : </InsertLabel>
          <Insert
            placeholder="請填入現金股利..."
            type="number"
            ref={this.cashValue}
            name="cashValue"
            onChange={this.onChangeCash}
        />
        <InsertBtn type="submit">+</InsertBtn>
        { !isPayment ? null : <Alert>
          現金股利大於或等於2萬元，應繳補充保費： <span>{charge}</span>
        </Alert>}
      </Form>
    )
  }
}

export default AddItem
