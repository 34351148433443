import React, { Component } from 'react'
import AddItem from './AddItem'
import List from './List'
import style from './style.module.scss'

class App extends Component {
  state = {
    lists: {}
  }

  addItem = item => {
    const lists = { ...this.state.lists }
    lists[`item${Date.now()}`] = item
    this.setState({
      lists: lists
    })
  }

  render() {
    const lists = this.state.lists
    return (
      <div className="container">
        <div className={style.stockWrap}>
          <AddItem addItem={this.addItem} />
          <h1>股利列表</h1>
          <ul className={style.listWrap}>
            {Object.keys(lists).map(key => (
              <List key={key} details={this.state.lists[key]} />
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

export default App
